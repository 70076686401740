import React from 'react';

import SkillList from '../components/SkillList';
import LanguageList from '../components/LanguageList';
import ToolList from '../components/ToolList';

interface IAbilities {
  skills: any;
  languages: any;
  tools: any;
}

const Abilities: React.FC<IAbilities> = ({ skills, languages, tools }) => {
  return (
    <>
      <SkillList {...skills} />
      <hr />
      <LanguageList {...languages} />
      <hr />
      <ToolList {...tools} />
    </>
  );
};

export default Abilities;
