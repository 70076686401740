import React from 'react';
import { SectionType } from '../types';

const SectionLayout: React.FC<SectionType> = ({
  containerStyle = 'white',
  sectionName,
  title,
  leadText,
  leadBy,
  enabled,
  children,
}) => {
  if (!enabled) return null;

  return (
    <div className={`background-${containerStyle} section ${sectionName}`}>
      <div className="overlay">
        <div id={sectionName} className="container">
          <h2>{title}</h2>
          <p className="lead">
            {leadText}
            {!!leadBy && (
              <>
                <br />- {leadBy}
              </>
            )}
          </p>
          <hr />
          {children}
        </div>
      </div>
    </div>
  );
};

export default SectionLayout;
