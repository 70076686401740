import React from 'react';
import { Helmet } from 'react-helmet-async';

import SectionLayout from './layout/SectionLayout';

import Jumbotron from './components/Jumbotron';
// import LoaderComponent from './components/LoaderComponent';
import NavBar from './components/NavBar';

import { Profile, Experiences, Abilities, Interests, Projects, Contact } from './sections';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './styles/index.scss';

import resumeData from './data/resume.json';

import { MetaType } from './types';

const sectionComponentMapping: any = {
  profile: Profile,
  experiences: Experiences,
  abilities: Abilities,
  interests: Interests,
  projects: Projects,
  contact: Contact,
};

const App: React.FC = () => {
  const {
    site_config: { meta },
    sections,
  } = resumeData;

  const sortedEnabledSections = sections.filter((section) => section.enabled).sort((a, b) => a.position - b.position);

  const metaTagGenerator = (metaObj: MetaType) => {
    const metaKeys = Object.keys(metaObj);
    return metaKeys.map((key, index) => (
      <meta key={`meta${index}`} name={key} content={metaObj[key as keyof typeof metaObj] as string} />
    ));
  };

  return (
    <>
      <Helmet>
        <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
        <meta http-equiv="content-script-type" content="text/javascript" />
        <meta http-equiv="content-style-type" content="text/css" />
        <meta http-equiv="content-language" content="en" />
        {metaTagGenerator(meta)}
      </Helmet>
      {/* <LoaderComponent /> */}
      <Jumbotron />
      <NavBar sections={sortedEnabledSections} />

      {sortedEnabledSections.map(({ template, template_data, ...section }, index) => {
        const Component = sectionComponentMapping[template];

        return (
          <SectionLayout key={`section-${index}`} {...section}>
            <Component {...template_data} />
          </SectionLayout>
        );
      })}
    </>
  );
};

export default App;
