import React from 'react';
import Scrollspy from 'react-scrollspy';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { SectionType } from '../types';

const NavBar: React.FC<{ sections: SectionType[] }> = ({ sections }) => {
  const [isFixed, setFixed] = React.useState<boolean>(false);

  React.useEffect(() => {
    const onScroll = () => {
      const currentPosition = window.pageYOffset;
      const windowHeight = window.innerHeight;

      console.log(`Scrolling to ${currentPosition} with height ${windowHeight}`);
      if (currentPosition > windowHeight - 60) {
        setFixed(true);
      }

      if (currentPosition <= windowHeight - 60) {
        setFixed(false);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  });

  return (
    <Navbar expand="lg" className={`navbar-default${isFixed && ' fixed'}`}>
      <Navbar.Collapse id="basic-navbar-nav">
        <Scrollspy
          componentTag={Nav}
          items={sections.map((section) => section.sectionName)}
          className="nav"
          currentClassName="current"
        >
          {sections.map((section, index) => (
            <Nav.Link key={`link-${index}`} href={`#${section.sectionName}`}>
              {section.sectionName}
            </Nav.Link>
          ))}
        </Scrollspy>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
