import React from 'react';
import GitHubButton from 'react-github-btn';

import siteImages from '../utils/images';
import { calculateAge } from '../utils/helpers';

interface IProfile {
  name: string;
  avatar_url: string;
  date_of_birth: string;
  bio: string;
  github: string;
  location: string;
}

const Profile: React.FC<IProfile> = ({ name, avatar_url, date_of_birth, bio, github, location }) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <h3>About me</h3>
        <p>{bio}</p>
      </div>
      <div className="col-md-4 text-center">
        <img
          id="profilePicture"
          src={siteImages[avatar_url]}
          width="246"
          height="246"
          alt="Rodrigo Barreto - Full Stack Developer"
        />
      </div>
      <div className="col-md-4">
        <h3>Details</h3>
        <p>
          <strong>Name:</strong>
          <br />
          {name}
          <br />
          <strong>Age:</strong>
          <br />
          {calculateAge(date_of_birth)} years old
          <br />
          <strong>Location:</strong>
          <br />
          {location}
        </p>

        {!!github && (
          <GitHubButton href={github} data-size="large" aria-label="Follow @rcabarreto on GitHub">
            Follow @rcabarreto
          </GitHubButton>
        )}
      </div>
    </div>
  );
};

export default Profile;
