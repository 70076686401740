import React from 'react';

import EducationList from '../components/EducationList';
import CareerList from '../components/CareerList';

interface IExperiences {
  education: any;
  career: any;
}

const Experiences: React.FC<IExperiences> = ({ education, career }) => {
  return (
    <>
      <EducationList {...education} />
      <hr />
      <CareerList {...career} />
    </>
  );
};

export default Experiences;
