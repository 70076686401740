import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { dateFormat } from '../utils/helpers';
import { ICareerList, ICareerListItem } from '../types';

const ListItem: React.FC<ICareerListItem> = ({
  visible,
  title,
  specification,
  location,
  level,
  description,
  start_date,
  end_date,
}) => {
  return (
    <div className="experience row">
      <div className="col-md-4">
        <h4>{title}</h4>
        <p className="experience-period">
          {dateFormat(start_date)} - {dateFormat(end_date)}
        </p>
      </div>
      <div className="col-md-8">
        <p>
          <strong>
            {level} - {specification}
          </strong>
          <span className="hidden-phone">{description}</span>
          <span className="experience-details">
            <span className="location">
              <FaMapMarkerAlt className="icon" />
              {location}
            </span>
          </span>
        </p>
      </div>
    </div>
  );
};

const EducationList: React.FC<ICareerList> = ({ title, list }) => {
  return (
    <>
      <h3>{title}</h3>
      <div className="experiences">
        {list.map((item, index) => (
          <ListItem key={`educationItem-${index}`} {...item} />
        ))}
      </div>
    </>
  );
};

export default EducationList;
