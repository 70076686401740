import React from 'react';
import { FaMapMarkerAlt, FaLink } from 'react-icons/fa';
import { dateFormat } from '../utils/helpers';

interface ICareerListItem {
  visible: boolean;
  title: string;
  about: string;
  specification: string;
  location: string;
  level: number;
  description: string;
  start_date?: string;
  end_date?: string;
  link?: string;
}

interface ICareerList {
  title: string;
  list: ICareerListItem[];
}

const ListItem: React.FC<ICareerListItem> = ({
  visible,
  title,
  about,
  specification,
  location,
  level,
  description,
  start_date,
  end_date,
  link,
}) => {
  return (
    <div className="experience row">
      <div className="col-md-4">
        <h4>{title}</h4>
        <p className="experience-period">
          {dateFormat(start_date)} - {!!end_date ? dateFormat(end_date) : 'current'}
        </p>
        <p className="about">{about}</p>
      </div>
      <div className="col-md-8">
        <p>
          <strong>
            {level} - {specification}
          </strong>
          <span className="hidden-phone">{description}</span>
          <span className="experience-details">
            <span className="location">
              <FaMapMarkerAlt className="icon" />
              {location}
            </span>
            {!!link && (
              <>
                <span className="seperator">|</span>
                <span className="link">
                  <FaLink className="icon" />
                  <a href={link} target="_blank" rel="noreferrer">
                    {link}
                  </a>
                </span>
              </>
            )}
          </span>
        </p>
      </div>
    </div>
  );
};

const CareerList: React.FC<ICareerList> = ({ title, list }) => {
  return (
    <>
      <h3>{title}</h3>
      <div className="experiences">
        {list.map((item, index) => (
          <ListItem key={`careerItem-${index}`} {...item} />
        ))}
      </div>
    </>
  );
};

export default CareerList;
