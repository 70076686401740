import React from 'react';
import { splitArray } from '../utils/helpers';

interface ITwoColumnList {
  title: string;
  list: any[];
  RenderItem: React.ElementType;
}
const TwoColumnList: React.FC<ITwoColumnList> = ({ title, list, RenderItem }) => {
  const { firstHalf, secondHalf } = splitArray(list);

  return (
    <>
      <h3>{title}</h3>
      <div className="row">
        <div className="col-md-6">
          <ul className="no-bullets">
            {firstHalf.map((item, index) => (
              <RenderItem key={`skilllist1-${index}`} {...item} />
            ))}
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="no-bullets">
            {secondHalf.map((item, index) => (
              <RenderItem key={`skilllist2-${index}`} {...item} />
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TwoColumnList;
