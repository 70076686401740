import React from 'react';
import siteImages from '../utils/images';

interface IInterests {
  list: [{ title: string; description: string; image: string; visible: boolean }];
}

const Interests: React.FC<IInterests> = ({ list }) => {
  const enabledItems = list.filter((item) => item.visible);
  return (
    <>
      {enabledItems.map((item, index) => {
        const { title, description, image } = item;
        return (
          <div key={`interests-${index}`} className={`row ${index % 2 !== 0 && 'flex-row-reverse'}`}>
            <div className={`col-md-4 ${index % 2 === 0 ? 'left' : 'right'}`}>
              <img src={siteImages[image]} alt={title} width="220" height="220" />
            </div>
            <div className="col-md-8 interest-content">
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Interests;
