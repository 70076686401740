import profilePicture from '../assets/images/profile-picture.jpg';

import circuit from '../assets/images/interests/circuit.png';
import music from '../assets/images/interests/music.png';
import dog from '../assets/images/interests/dog-1.png';
import pint from '../assets/images/interests/pint.png';
import luggage from '../assets/images/interests/luggage.png';
import book from '../assets/images/interests/book.png';

// project images
import contabilityProjectProject from '../assets/images/projects/contability.com.br-2018.07.17.png';
import weatherProject from '../assets/images/projects/weather.barreto.io-2018.12.04-10-49-46.png';
import todoProject from '../assets/images/projects/todo.barreto.io-2018.12.04-11-53-59.png';
import onofreProject from '../assets/images/projects/onofre.com.br-2018.07.02-19-29-00.png';
import etnaProject from '../assets/images/projects/etna-2017-04-18 12-40-19.png';
import pontosmultiplusProject from '../assets/images/projects/pontosmultiplus-2017-04-18 12-39-00.png';
import terraProject from '../assets/images/projects/servicos.terra.com.br-2017-07-27-17-43-44.png';
import htecautomacaoProject from '../assets/images/projects/htecautomacao-2017-01-26.png';

export const siteImages: { [key: string]: string } = {
  profilePicture,
  circuit,
  music,
  dog,
  pint,
  luggage,
  book,
  contabilityProjectProject,
  weatherProject,
  todoProject,
  onofreProject,
  etnaProject,
  pontosmultiplusProject,
  terraProject,
  htecautomacaoProject,
};

export default siteImages;
