import React from 'react';
import logo from '../assets/images/newLogoWhite.svg';
import { FaChevronDown } from 'react-icons/fa';
import resumeData from '../data/resume.json';

const Jumbotron: React.FC = () => {
  const {
    jumbotron: { lead },
  } = resumeData;

  return (
    <div id="top" className="jumbotron" data-src="" data-position="">
      <div className="container">
        <div style={{ maxWidth: '400px', margin: '0 auto' }}>
          <img src={logo} className="App-logo" alt="logo" />
        </div>

        <p className="lead">{lead}</p>
      </div>

      <div className="overlay"></div>

      <a href="#profile" className="scroll-down">
        <FaChevronDown className="icon" />
      </a>
    </div>
  );
};

export default Jumbotron;
