import React from 'react';
import siteImages from '../utils/images';
import { MdOpenInNew } from 'react-icons/md';

interface IProjects {
  list: [
    {
      title: string;
      description: string;
      tags: string;
      image: string;
      link: string;
    }
  ];
}

const Projects: React.FC<IProjects> = ({ list }) => {
  return (
    <div className="row">
      {list.map((item, index) => {
        const { title, description, tags, image, link } = item;
        return (
          <div key={`projects-${index}`} className="col-md-6 col-sm-12 col-xs-12">
            <figure className="effect">
              <img src={siteImages[image]} alt={title} />
              <figcaption>
                <h3>{title}</h3>
                <p>{description}</p>
                <p>
                  <strong>Tags:</strong> <br />
                  {tags}
                </p>
                {!!link && (
                  <a href={link} target="_blank" rel="noreferrer">
                    View more
                  </a>
                )}
                <span className="icon">
                  <MdOpenInNew className="glyphicon glyphicon-new-window" />
                </span>
              </figcaption>
            </figure>
          </div>
        );
      })}
    </div>
  );
};

export default Projects;
