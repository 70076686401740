import React from 'react';
import { IoStar } from 'react-icons/io5';

const StarRating: React.FC<{ level: number }> = ({ level }) => {
  const levelArray: number[] = [1, 2, 3, 4, 5];
  return (
    <>
      {levelArray.map((index) => (
        <IoStar key={`star-${index}`} className={`star-icon ${level < index ? '' : 'filled'}`} />
      ))}
    </>
  );
};

export default StarRating;
