import React from 'react';
import { sortByLevel } from '../utils/helpers';

import TwoColumnList from './TwoColumnList';
import StarRating from './StarRating';

interface IListItem {
  visible: boolean;
  title: string;
  description: string;
  level: number;
}

interface ILanguageList {
  title: string;
  list: IListItem[];
}

const ListItem: React.FC<IListItem> = ({ visible, title, description, level }) => (
  <li>
    <span className="ability-title">
      {title} ({description})
    </span>
    <span className="ability-score">
      <StarRating level={level} />
    </span>
  </li>
);

const LanguageList: React.FC<ILanguageList> = ({ title, list }) => (
  <TwoColumnList title={title} list={list.sort(sortByLevel)} RenderItem={ListItem} />
);

export default LanguageList;
