import React from 'react';
import GitHubButton from 'react-github-btn';

import {
  FaLinkedin,
  FaGithub,
  FaTwitter,
  FaFacebookSquare,
  FaInstagramSquare,
  FaEnvelope,
  FaGlobeAmericas,
  FaBitbucket,
  FaSkype,
} from 'react-icons/fa';

import { invert, stripProtocol } from '../utils/helpers';

interface IContact {
  contact_details: {
    linkedin: string;
    github: string;
    facebook: string;
    instagram: string;
    blog: string;
    twitter: string;
    skype: string;
    email: string;
    bitbucket: string;
  };
}

const Contact: React.FC<IContact> = ({ contact_details }) => {
  const { linkedin, github, facebook, instagram, blog, twitter, skype, email, bitbucket } = contact_details;
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <ul className="no-bullets">
            {!!linkedin && (
              <li>
                <a href={linkedin} target="_blank" rel="noreferrer">
                  <FaLinkedin className="icon" />
                  {stripProtocol(linkedin)}
                </a>
              </li>
            )}

            {!!github && (
              <li>
                <a href={github} target="_blank" rel="noreferrer">
                  <FaGithub className="icon" />
                  {stripProtocol(github)}
                </a>
              </li>
            )}

            {!!facebook && (
              <li>
                <a href={facebook} target="_blank" rel="noreferrer">
                  <FaFacebookSquare className="icon" />
                  {stripProtocol(facebook)}
                </a>
              </li>
            )}

            {!!twitter && (
              <li>
                <a href={twitter} target="_blank" rel="noreferrer">
                  <FaTwitter className="icon" />
                  {stripProtocol(twitter)}
                </a>
              </li>
            )}

            {!!instagram && (
              <li>
                <a href={instagram} target="_blank" rel="noreferrer">
                  <FaInstagramSquare className="icon" />
                  {stripProtocol(instagram)}
                </a>
              </li>
            )}
          </ul>
        </div>

        <div className="col-md-6">
          <ul className="no-bullets">
            {!!blog && (
              <li>
                <a href={blog} target="_blank" rel="noreferrer">
                  <FaGlobeAmericas className="icon" />
                  {stripProtocol(blog)}
                </a>
              </li>
            )}

            {!!skype && (
              <li>
                <a href="skype:rodrigo.barreto.rj?chat">
                  <FaSkype className="icon" />
                  {skype}
                </a>
              </li>
            )}

            {!!email && (
              <li>
                <a href={`mailto:${invert(email)}`}>
                  <FaEnvelope className="icon" />
                  <span style={{ unicodeBidi: 'bidi-override', direction: 'rtl' }}>{invert(email)}</span>
                </a>
              </li>
            )}

            {!!bitbucket && (
              <li>
                <a href={bitbucket} target="_blank" rel="noreferrer">
                  <FaBitbucket className="icon" />
                  {stripProtocol(bitbucket)}
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>

      <hr />

      {!!github && (
        <div className="text-center">
          <GitHubButton
            href="https://github.com/rcabarreto"
            data-size="large"
            aria-label="Follow @rcabarreto on GitHub"
          >
            Follow @rcabarreto
          </GitHubButton>
        </div>
      )}
    </>
  );
};

export default Contact;
