import React from 'react';
import { sortByLevel, yearsFrom } from '../utils/helpers';

import TwoColumnList from './TwoColumnList';
import StarRating from './StarRating';

interface IListItem {
  title: string;
  start_year: number;
  level: number;
  visible: boolean;
}

interface IToolList {
  title: string;
  list: IListItem[];
}

const ListItem: React.FC<IListItem> = ({ visible, title, start_year, level }) => (
  <li>
    <span className="ability-title">
      {title} <span className="small">({yearsFrom(start_year)}+ years)</span>
    </span>
    <span className="ability-score">
      <StarRating level={level} />
    </span>
  </li>
);

const ToolList: React.FC<IToolList> = ({ title, list }) => (
  <TwoColumnList title={title} list={list.sort(sortByLevel)} RenderItem={ListItem} />
);

export default ToolList;
