import React from 'react';
import { sortByLevel, yearsFrom } from '../utils/helpers';

import TwoColumnList from './TwoColumnList';
import StarRating from './StarRating';

interface IListItem {
  title: string;
  start_year: number;
  level: number;
  visible: boolean;
}

interface ISkillList {
  title: string;
  list: IListItem[];
}

const ListItem: React.FC<IListItem> = ({ visible, title, start_year, level }) => (
  <li>
    <span className="ability-title">
      {title} <span className="small">({yearsFrom(start_year)} years)</span>
    </span>
    <span className="ability-score">
      <StarRating level={level} />
    </span>
  </li>
);

const SkillList: React.FC<ISkillList> = ({ title, list }) => {
  const sortedList = list.sort(sortByLevel);

  return (
    <>
      <TwoColumnList title={title} list={sortedList} RenderItem={ListItem} />

      <div className="text-center project-referal">
        <p>This website is build with TypeScript using React and Bootstrap and it's hosted on Netlify.</p>
        <a
          href="https://github.com/rcabarreto/interactiveResume"
          className="btn btn-primary"
          target="_blank"
          rel="noreferrer"
        >
          See project on Github
        </a>
      </div>
    </>
  );
};

export default SkillList;
