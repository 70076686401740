import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

export const dateFormat = (date: any, format = 'MMM YYYY', utc = false) => {
  dayjs.extend(LocalizedFormat);
  return dayjs(date).format('MMMM YYYY');
};

export const stripProtocol = (currentItem: any) => {
  return currentItem.replace(/^https?:\/\//i, '');
};

export const invert = (str: string) => {
  return str.split('').reverse().join('');
};

export const sortByLevel = (a: any, b: any) => {
  if (a.level === b.level) {
    return b.level_detailed - a.level_detailed;
  }
  return b.level - a.level;
};

export const splitArray = (array: any[]) => {
  const half = Math.ceil(array.length / 2);
  const firstHalf = array.slice(0, half);
  const secondHalf = array.slice(half);

  return { firstHalf, secondHalf };
};

export const yearsFrom = (year: number) => {
  const startDate = new Date(year);
  const timeDifMs = Date.now() - startDate.getTime();
  const timeDiff = new Date(timeDifMs);

  return Math.abs(timeDiff.getUTCFullYear() - 1970);
};

export const calculateAge = (dateOfBirth: any) => {
  const birthday = new Date(dateOfBirth);
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
